.Container {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  grid-auto-rows: max-content;
  overflow: hidden;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  min-width: 350px;
  margin: 10px;
  width: 554px;
  height: 507px;
  border-radius: 8px;
  min-height: 200px;
  transition: background-color 350ms ease;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
  font-size: 1em;
}

.Container ul {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
  list-style: none;
  padding: 20px;
  margin: 0;
}

.Container.scrollable ul {
  overflow-y: auto;
}

.Container.placeholder {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.08);
}

.Container.placeholder:hover {
  border-color: rgba(0, 0, 0, 0.15);
}

.Container.hover {
  background-color: rgb(235, 235, 235, 1);
}

.Container.unstyled {
  overflow: visible;
  background-color: transparent !important;
  border: none !important;
}

.Container.horizontal {
  width: 100%;
}

.Container.horizontal ul {
  grid-auto-flow: column;
}

.Container.shadow {
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
}

.Container:focus-visible {
  border-color: transparent;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
}

.Header {
  display: flex;
  padding: 5px 20px;
  padding-right: 8px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.Header:hover .Actions > * {
  opacity: 1 !important;
}

.Actions {
  display: flex;
}

.Actions > *:first-child:not(:last-child) {
  opacity: 0;
}

.Actions > *:first-child:not(:last-child):focus-visible {
  opacity: 1;
}
